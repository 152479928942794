import instance from '@/utils/request/index';
import { AxiosResponse } from 'axios';

export default {
  /**
   * 系统-登录
   */
  async authLogin(name: string, password: string) {
    const response: AxiosResponse<AuthLoginResponse> = await instance.post('/admin/login', { usernameOrPhone: name, password });
    return response.data;
  },
  /**
   * 系统-admin-用户权限获得
   */
  async getAdminPermission(id: unknown) {
    const response: AxiosResponse<PermissionList[]> = await instance.post('/admin/getAdminPermission', { id });
    return response.data;
  },
  /**
   * 系统-修改密码
   */
  async changePassword(id: unknown, originalPassword: string, password: string) {
    return await instance.post('/admin/changePassword', { id, originalPassword, password });
  },
  /**
   * 系统-登出
   */
  async authLogout(id: unknown) {
    return await instance.post('/admin/logout', { id });
  },
  /**
   * admin-获取page列表
   */
  async getAdminPage(param: unknown) {
    const response: AxiosResponse<AdminUser> = await instance.post('/admin/getAdminPage', param);
    return response.data;
  },
  /**
   * 管理员-新增
   */
  async generateAdmin(param: unknown) {
    const response: AxiosResponse<UpdateAdmin> = await instance.post('/admin/generateAdmin', param);
    return response.data;
  },
  /**
   * 管理员-更新admin信息
   */
  async updateAdmin(param: unknown) {
    return await instance.post('/admin/updateAdmin', param);
  },
  /**
   * 管理员-停用
   */
  async disableAdmin(param: unknown) {
    return await instance.post('/admin/disableAdmin', param);
  },
  /**
   * 管理员-启用
   */
  async enableAdmin(param: unknown) {
    return await instance.post('/admin/enableAdmin', param);
  },
  /**
   * 管理员-重置密码
   */
  async resetPassword(param: unknown) {
    const response: AxiosResponse<UpdateAdmin> = await instance.post('/admin/resetPassword', param);
    return response.data;
  },
  /**
   * role-获取page列表
   */
  async getRolePage(param: unknown) {
    const response: AxiosResponse<Role> = await instance.post('/role/getRolePage', param);
    return response.data;
  },
  /**
   * 管理员-添加新角色
   */
  async addRole(param: unknown) {
    return await instance.post('/role/addRole', param);
  },
  /**
   * 管理员-修改角色
   */
  async updateRole(param: unknown) {
    return await instance.post('/role/updateRole', param);
  },
  /**
   * 管理员-删除角色
   */
  async deleteRole(param: unknown) {
    return await instance.post('/role/deleteRole', param);
  },
  /**
   * 管理员-获取未授权用户
   */
  async getUnauthorizedRoleAdminList(param: unknown) {
    const response: AxiosResponse<AdminUser> = await instance.post('/role/getUnauthorizedRoleAdminPage', param);
    return response.data;
  },
  /**
   * 管理员-获取授权用户
   */
  async getAuthorizedRoleAdminList(param: unknown) {
    const response: AxiosResponse<AdminUser> = await instance.post('/role/getAuthorizedRoleAdminPage', param);
    return response.data;
  },
  /**
   * 管理员-授权用户
   */
  async bindAdmin(param: unknown) {
    return await instance.post('/role/bindAdmin', param);
  },
  /**
   * 管理员-取消授权用户
   */
  async cancelBindAdmin(param: unknown) {
    return await instance.post('/role/cancelBindAdmin', param);
  },
  /**
   * 管理员-获取权限列表
   */
  async getPermissionNode(param: unknown) {
    const response: AxiosResponse<PermissionList[]> = await instance.post('/permission/getPermissionNode', param);
    return response.data;
  },
  /**
   * 管理员-新增权限
   */
  async addPermission(param: unknown) {
    return await instance.post('/permission/addPermission', param);
  },
  /**
   * 管理员-编辑权限
   */
  async editPermission(param: unknown) {
    return await instance.post('/permission/updatePermission', param);
  },
  /**
   * 管理员-删除权限
   */
  async deletePermission(param: unknown) {
    return await instance.post('/permission/deletePermission', param);
  },
  /**
   * 管理员-role-角色获取权限Node
   */
  async getRolePermissionNode(param: unknown) {
    const response: AxiosResponse<PermissionList[]> = await instance.post('/role/getRolePermissionNode', param);
    return response.data;
  },
  /**
   * 管理员-role-配置权限
   */
  async updatePermission(param: unknown) {
    return await instance.post('/role/updatePermission', param);
  },
  /**
   * 管理员-标签列表
   */
  async getLabelList(param: unknown) {
    return await instance.post('/tag/getTagList', param);
  },
  /**
   * 管理员-标签列表 分页
   */
  async getLabelPage(param: unknown) {
    return await instance.post('/tag/getTagPage', param);
  },
  /**
   * 管理员-标签枚举列表
   */
  async getLabelTypeList() {
    return await instance.post('/tag/getTagTypes');
  },
  /**
   * 管理员-新增标签
   */
  async addLabel(param: unknown) {
    return await instance.post('/tag/addTag', param);
  },
  /**
   * 管理员-修改标签
   */
  async editLabel(param: unknown) {
    return await instance.post('/tag/updateTag', param);
  },
  /**
   * 管理员-删除标签
   */
  async deleteLabel(param: unknown) {
    return await instance.post('/tag/deleteTag', param);
  },
  /**
   * 管理员-上传文件
   */
  async uploadFile(file: unknown) {
    return await instance.post('/file/upload', file);
  },
  /**
   * 管理员-批量上传文件
   */
  async uploadFiles(file: unknown) {
    return await instance.post('/file/uploadMultipleFiles', file);
  },
  /**
   * 管理员-获取所有语言列表
   */
  async getLanguage() {
    return await instance.post('/language/getAllLanguageList');
  },
  /**
   * 管理员-语言列表
   */
  async getLanguageByKeyword(param: unknown) {
    return await instance.post('/language/getLanguageList', param);
  },
  /**
   * 管理员-获取所有Icon
   */
  async getIcon(param) {
    return await instance.post('/icon/getIconPage', param);
  },
  /**
   * 管理员-新增Icon
   */
  async addIcon(param) {
    return await instance.post('/icon/addIcon', param);
  },
  /**
   * 管理员-删除Icon
   */
  async delIcon(param) {
    return await instance.post('/icon/deleteIcon', param);
  },
};
