// 定义 Store
export const useStationStore = defineStore(
  'station',
  () => {
    const languageList: any = ref([]);
    const setLanguageList = async (val: any) => {
      languageList.value = [...val];
    };

    return {
      languageList,
      setLanguageList,
    };
  },
  {
    persist: true,
  }
);
